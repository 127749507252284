import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const NBSP = '\u00A0'

function extractHours (minutes) {
  return Math.floor(minutes / 60)
}

function extractMinutes (minutes) {
  return minutes - extractHours(minutes) * 60
}

function formatHours (hours) {
  return hours > 0 ? hours : ''
}

function formatMinutes (minutes) {
  return Math.round(minutes)
}

const Duration = ({ minutes, noNbsp }) => {
  const hours = extractHours(minutes)

  return (
    <React.Fragment>
      {
        hours > 0 &&
        <FormattedMessage
          id='components.duration.hours'
          defaultMessage='{hours} h'
          values={{ hours: formatHours(hours) }}
        />
      }
      {(!noNbsp || hours > 0) && NBSP}
      <FormattedMessage
        id='components.duration.minutes'
        defaultMessage='{minutes} min'
        values={{ minutes: formatMinutes(extractMinutes(minutes)) }}
      />
    </React.Fragment>
  )
}

Duration.propTypes = {
  minutes: PropTypes.number.isRequired,
  noNbsp: PropTypes.bool
}

export default Duration
